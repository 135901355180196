import { useState, useEffect } from 'react';
import {fetchData , putData} from './AwsFunctions';

function App() {
  const [value, setValue] = useState();

  useEffect(()=>{
    fetchDataFormDynamoDb()
  },[])

  const fetchDataFormDynamoDb = () => {
    fetchData('jtriorg', setValue);
  }
  
  const addDataToDynamoDB = async () => {
    const userData = {
      "section": 'main',
      "timestamp": Date.now(),
      "value": value,
    }
    
    await putData('jtriorg' , userData)
  }
  
  return <>
  <textarea style={{width: 700}} rows={30} value={value} onChange={(e) => setValue(e.target.value)}/>
    <button onClick={() => addDataToDynamoDB()}> Put </button>
  </>
}

export default App;