import * as AWS from 'aws-sdk'

const configuration: ConfigurationOptions = {
    region: 'us-east-1',
    secretAccessKey: 'kFtjIbcrqkmyVpZav7LKXdH2rOevN2Zd1jQN+PPX',
    accessKeyId: 'AKIAQHQAKA7MZ45ZVR6Y'
}
  
AWS.config.update(configuration)
  
const docClient = new AWS.DynamoDB.DocumentClient()

export const fetchData = (tableName, setRecord) => {
    var params = {
        TableName: tableName,
        KeyConditionExpression: '#s = :section',
        ExpressionAttributeNames: {
            '#s': 'section'
        },
        ExpressionAttributeValues: {
            ':section': 'main'
        },
        ScanIndexForward: false,
        Limit: 1
    }

    docClient.query(params, function (err, data) {
        if (!err) {
            setRecord(data.Items.at(-1).value);
        }
    })
}

export const putData = (tableName , data) => {
    var params = {
        TableName: tableName,
        Item: data
    }

    docClient.put(params, function (err, data) {
        if (err) {
            console.log('Error', err)
        } else {
            console.log('Success', data)
        }
    })
}